import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../components/layout/NonStretchedImage';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker, { Features } from '../../components/cta/FeatureListBreaker';
import GetStarted from '../../components/cta/GetStarted';
import FeatureRow from '../../components/section/FeatureRow';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 1440px 519px;
  background-image: url(${props => props.backgroundImage});
`;

const Hero = styled.div`
  padding: 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 45%;
    margin-right: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.brand160};
`;

const header = {
  backgroundColor: 'brand180',
  invertColors: true,
};

const Page = ({ data }) => (
  <Body header={header}>
    <SEO
      title="Time & attendance - Rotaready"
      description="Clocking in and out has never been so simple. Capture employee attendance with the Rotaready app, manage overtime and approve timesheets for payroll."
      url="time-attendance"
    />
    <DarkWrapper
      backgroundImage={data.heroBackgroundMask.childImageSharp.fixed.src}
      title="Time & attendance"
    >
      <ResponsiveContainer>
        <Hero>
          <HeroInnerContainer>
            <HeroBody>
              <HeroH1 color="brand" text="Time & attendance" />
              <HeroH2 color="white" text="Time and attendance monitoring made simple" />

              <H4 uistyle="white">
                Trustworthy attendance records are crucial to paying people
                for the hours they work. An extra few minutes here and there
                adds up to a costly increase in labour spend over time.
                <br />
                <br />
                When your team clocks in and out with Rotaready, you'll have
                instantly accurate timesheets, rounded and ready for payroll.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </CtaButtons>
            </HeroBody>

            <HeroIllustration>
              <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
            </HeroIllustration>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </DarkWrapper>

    <FeatureListBreaker
      rows={1}
      excludeFeature={Features.TIME_ATTENDANCE}
      headingText="Other features"
    />

    <FeatureRow
      mirrorArrange
      title="Attendance issues detected automatically"
      illustrationImage={data.issuesIllustration.childImageSharp.fluid}
      illustrationAltText="A restaurant employee clocking in for a shift 75 mintues late within Rotaready"
    >
      <Subtitle text="Rotaready watches your staff attendance records around-the-clock, instantly flagging issues. Most can be resolved with one click." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Suspect attendance, missing shifts, breaks or clock-outs automatically detected" />
        <TextListItem text="Full audit trail of records and amendments" />
        <TextListItem text="Holidays and paid absence accounted for" />
        <TextListItem text="Accept or reject overtime with a single click" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="Mobile clock-in with GPS"
      illustrationImage={data.gpsIllustration.childImageSharp.fluid}
      illustrationAltText="Rotaready mobile clock-in with GPS"
    >
      <Subtitle text="Give your team the option to clock-in and out from their phone with the Rotaready app." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Control the distance from which employees can clock-in" />
        <TextListItem text="Monitor staff time with perfect accuracy" />
        <TextListItem text="Use in harmony with on-site clock-in devices, or go mobile-only" />
        <TextListItem text="Eliminate time wasted clocking-in, forgotten PIN codes or swipe cards" />
      </UL>
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Make buddy clocking a thing of the past"
      illustrationImage={data.clockInIllustration.childImageSharp.fluid}
      illustrationAltText="Rotaready clock-in using a PIN and photo capture"
    >
      <Subtitle text="Eliminate the need for expensive hardware with the Rotaready Kiosk app, available on Android and iPad tablets." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Clock-in using a tablet you already have on-site, or take one from us" />
        <TextListItem text="Optionally require a PIN code or photo capture for extra security" />
        <TextListItem text="No need for expensive professional installation" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="Magically integrate with rotas & payroll"
      illustrationImage={data.exportDataIllustration.childImageSharp.fluid}
      illustrationAltText="Exporting data from Rotaready to Excel, PDF and CSV"
    >
      <Subtitle text="Let Rotaready auto-magically match attendance records with shifts. Then sit back and watch as your rounding rules are instantly applied." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Automatic marriage of shift and attendance records" />
        <TextListItem text="Create custom rounding rules, also known as sanitisation" />
        <TextListItem text="Feed straight into payroll or export timesheets in many formats" />
        <TextListItem text="Full visibility over scheduled vs actual hours" />
      </UL>
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Tailor to your needs"
      illustrationImage={data.settingsIllustration.childImageSharp.fluid}
      illustrationAltText="Setting permission levels within Rotaready"
    >
      <Subtitle text="We understand that every business is a little bit different. That's why there are hundreds of settings to configure Rotaready to your needs." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Flexible permission levels to control access rights" />
        <TextListItem text="Set rules for better cost management" />
        <TextListItem text="Clock-in and out via mobile, tablet or both" />
        <TextListItem text="Enable shift reminders and more" />
      </UL>
    </FeatureRow>

    <GetStarted />
  </Body>
);

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "misc/hero-time-attendance.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    heroBackgroundMask: file(relativePath: { eq: "misc/hero-mask-brand180.png" }) {
      childImageSharp {
        fixed(height: 519, width: 1440, quality: 100) {
          src
        }
      }
    }

    issuesIllustration: file(relativePath: { eq: "illustrations/attendance-issues.png" }) {
      childImageSharp {
        fluid(maxWidth: 499, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    gpsIllustration: file(relativePath: { eq: "illustrations/attendance-gps.png" }) {
      childImageSharp {
        fluid(maxWidth: 562, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    clockInIllustration: file(relativePath: { eq: "illustrations/attendance-clock-in.png" }) {
      childImageSharp {
        fluid(maxWidth: 382, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    exportDataIllustration: file(relativePath: { eq: "illustrations/attendance-export-data.png" }) {
      childImageSharp {
        fluid(maxWidth: 477, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    settingsIllustration: file(relativePath: { eq: "illustrations/attendance-settings.png" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
